<template>
  <div>
    <AppHeader />
    <div class="btn-wrapper">
      <button class="btn btn-light" @click="$router.go(-1)">
        <svg class="arrow-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
          <path fill="#199057" d="M9.57 5.18c.19 0 .38.07.53.22.29.29.29.77 0 1.06L4.56 12l5.54 5.54c.29.29.29.77 0 1.06-.29.29-.77.29-1.06 0l-6.07-6.07a.754.754 0 0 1 0-1.06L9.04 5.4c.15-.15.34-.22.53-.22Z" />
          <path fill="#199057" d="M3.67 11.25H20.5c.41 0 .75.34.75.75s-.34.75-.75.75H3.67c-.41 0-.75-.34-.75-.75s.34-.75.75-.75Z" />
        </svg>
        Назад
      </button>
      <button class="btn btn-cancel" @click="cancelBook">Отменить бронирование</button>
      <div v-if="bookingMessage" :class="{'success-message': bookingSuccess, 'error-message': !bookingSuccess}">
        {{ bookingMessage }}
      </div>
    </div>

    <!-- Отображение объекта -->
    <div v-if="object">
      <div class="objects">
        <div class="objects-card">
          <h2>{{ object.nm }}</h2>
          <p class="object-date">{{ formattedDate }}</p>
          <div class="object-addr-wrapper">
            <p class="object-addr">{{ object.location }}</p>
          </div>
          <p class="object-time">{{ "Заезд в: " + time1 }}</p>
          <p class="object-time">{{ "Выезд до: " + time2 }}</p>
          <div class="object-price-wrapper">
            <p class="object-price"><span>Сумма</span></p>
            <p class="object-price">{{ object.total_price }} р.</p>
          </div>
        </div>
        <div class="object-image">
          <img :src="imageSrc" alt="Card" />
        </div>
      </div>
    </div>

    <!-- Отображение номеров -->
    <div v-if="object">
      <div class="objects">
        <div class="card">
          <h2>Описание</h2>
          <h3>{{ object.nm }}</h3>
          <div class="wrapper">
            <div class="card-wrapper">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none">
                <path fill="#199057" fill-rule="evenodd" d="M9.566 2.853a3.235 3.235 0 1 0 0 6.47 3.235 3.235 0 0 0 0-6.47ZM3.978 6.088a5.588 5.588 0 1 1 11.176 0 5.588 5.588 0 0 1-11.176 0Z" clip-rule="evenodd"/>
                <path fill="#199057" fill-rule="evenodd" d="M3.68 13.946a9.986 9.986 0 0 1 15.395 5.018 1.177 1.177 0 0 1-1.12 1.536H1.176a1.176 1.176 0 0 1-1.12-1.536 9.986 9.986 0 0 1 3.624-5.018Zm5.886.434a7.633 7.633 0 0 0-6.582 3.767h13.163a7.633 7.633 0 0 0-6.581-3.767Z" clip-rule="evenodd"/>
              </svg>
              <p class="card-text">{{ object.max_guests }} чел.</p>
            </div>
            <div class="card-wrapper card-wrapper-gap-1">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" fill="none">
                <path stroke="#199057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.538 21.5h16.924c.85 0 1.538-.689 1.538-1.538V3.038c0-.85-.689-1.538-1.538-1.538H2.538C1.688 1.5 1 2.189 1 3.038v16.924c0 .85.689 1.538 1.538 1.538ZM1 10.73 9.462 1.5M21 10.73 12.54 1.5M1 12.269l8.462 9.23M21 12.269l-8.461 9.23"/>
              </svg>
              <p class="card-text">{{ object.square }} м²</p>
            </div>
            <div class="card-wrapper card-wrapper-gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" fill="none">
                <path stroke="#199057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.308 6.885h12.308a.77.77 0 0 1 .769.769v6.923a3.077 3.077 0 0 1-3.077 3.077H5.616a3.077 3.077 0 0 1-3.077-3.077V7.654a.77.77 0 0 1 .769-.77v0ZM1 21.5h20M16.385 8.423h1.538a3.077 3.077 0 1 1 0 6.154h-1.538M4.846 1.5v1.538M12.539 1.5v1.538M8.692 1.5v1.538"/>
              </svg>
              <p class="card-text">{{ object.with_breakfast === '1' ? 'Завтрак включен' : 'Завтрак не включен' }}</p>
            </div>
            <div class="card-wrapper card-wrapper-gap-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="23" fill="none">
                <path stroke="#199057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 16.5c1.105 0 2-1.343 2-3s-.895-3-2-3-2 1.343-2 3 .895 3 2 3ZM9 7.5c1.105 0 2-1.343 2-3s-.895-3-2-3-2 1.343-2 3 .895 3 2 3ZM19 7.5c1.105 0 2-1.343 2-3s-.895-3-2-3-2 1.343-2 3 .895 3 2 3ZM25 16.5c1.105 0 2-1.343 2-3s-.895-3-2-3-2 1.343-2 3 .895 3 2 3ZM20 17.5c0 2.76-3.24 4-6 4s-6-1.24-6-4 2-7 6-7 6 4.24 6 7Z"/>
              </svg>
              <p class="card-text">{{ object.with_pets === '1' ? 'Можно с животными' : 'Нельзя с животными' }}</p>
            </div>
            <div class="card-wrapper card-wrapper-gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" fill="none">
                <path stroke="#199057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.23 10.731h-6.153a.77.77 0 0 0-.77.77v9.23c0 .425.345.77.77.77h6.154a.77.77 0 0 0 .769-.77v-9.23a.77.77 0 0 0-.77-.77ZM20.23 1.5h-6.153a.77.77 0 0 0-.77.77v3.092c0 .424.345.769.77.769h6.154A.77.77 0 0 0 21 5.36V2.27a.77.77 0 0 0-.77-.769ZM7.923 1.5H1.77A.77.77 0 0 0 1 2.27v9.23c0 .425.344.77.77.77h6.153a.77.77 0 0 0 .77-.77V2.27a.77.77 0 0 0-.77-.77ZM7.923 16.87H1.77a.77.77 0 0 0-.769.769v3.092c0 .425.344.77.77.77h6.153a.77.77 0 0 0 .77-.77V17.64a.77.77 0 0 0-.77-.77Z"/>
              </svg>
              <p class="card-text">{{ object.num_of_rooms }} комнаты</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './AppHeader.vue';
import AppFooter from './AppFooter.vue';

function formatUnixToDate(unixTime) {
  const date = new Date(unixTime * 1000);  // Преобразуем UnixTime в миллисекунды
  const day = String(date.getDate()).padStart(2, '0');  // Добавляем ведущий 0
  const month = String(date.getMonth() + 1).padStart(2, '0');  // Месяцы в JS начинаются с 0
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;  // Возвращаем дату в формате DD.MM.YYYY
}
export default {
  name: 'BookingInfo',
  components: {
    AppHeader,
    AppFooter,
  },
  props: {
    id: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      object: null, // Объект из API
      time1: '16:00', // Время заезда по умолчанию
      time2: '12:00', // Время выезда по умолчанию
      bookingMessage: '',  // Сообщение о результате бронирования
      bookingSuccess: false // Статус бронирования
    };
  },
  methods: {
    async fetchReservations() {
      try {
        const myHeaders = new Headers();
        myHeaders.append("token", this.$store.getters.token);

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow"
        };

        // Используем id из props для фильтрации по id
        const response = await fetch(`https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/vrs?filters[id-e]=${this.id}`, requestOptions);
        const data = await response.json();

        if (response.ok) {
          this.object = data.data[0]; // Получаем объект бронирования
        } else {
          console.error("Ошибка при загрузке данных:", data.errors);
        }
      } catch (error) {
        console.error("Ошибка запроса:", error);
      }
    },
    async cancelBook() {
      // Открываем всплывашку с подтверждением
      const userConfirmed = window.confirm('Вы уверены, что хотите отменить бронирование?');

      if (userConfirmed) {
        // Получаем причину отмены от пользователя
        const note = prompt('Пожалуйста, укажите причину отмены бронирования:');

        if (note) {
          try {
            const myHeaders = new Headers();
            myHeaders.append("token", this.$store.getters.token); // Токен из store
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
              "sts_id": "canc",  // Статус отмены
              "note": note       // Причина отмены
            });

            const requestOptions = {
              method: "PATCH",
              headers: myHeaders,
              body: raw,
              redirect: "follow"
            };

            const response = await fetch(`https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/res/${this.id}`, requestOptions);
            const result = await response.json();

            if (response.ok) {
              // Успешная отмена
              this.bookingMessage = 'Бронирование успешно отменено';
              this.bookingSuccess = true;
              this.$router.go(-1);
            } else {
              // Ошибка при отмене
              this.bookingMessage = 'Ошибка при отмене бронирования: ' + (result.errors ? result.errors[0].title : 'Неизвестная ошибка');
              this.bookingSuccess = false;
            }
          } catch (error) {
            console.error('Ошибка при отмене бронирования:', error);
            this.bookingMessage = 'Ошибка при отмене бронирования';
            this.bookingSuccess = false;
          }
        } else {
          this.bookingMessage = 'Отмена бронирования отменена. Причина не указана.';
          this.bookingSuccess = false;
        }
      }
    }
  },
  mounted() {
    this.fetchReservations(); // Получаем объект при монтировании компонента
  },
  computed: {
    // Форматирование дат заезда и выезда
    formattedDate() {
      if (this.object && this.object.start_dt && this.object.end_dt) {
        return `${formatUnixToDate(this.object.start_dt)} - ${formatUnixToDate(this.object.end_dt)}`;
      }
      return 'Дата не указана';
    },
    imageSrc() {
      return this.object && this.object.file_id
          ? `https://лк.домшеринг.рф/file/${this.object.file_id}/${this.object.file_name}`
          : 'https://via.placeholder.com/200'; // Заглушка на случай отсутствия изображения
    },
    totalPrice() {
      return this.object ? this.object.total_price : '0';
    }
  }
};
</script>

<style scoped>
.btn {
  font-size: 14px;
  width: 100%;
}

@media (width >= 768px) {
  .btn {
    font-size: 16px;
    width: 330px;
  }
}

.btn-light {
  gap: 12px;
  width: 160px;
  height: 48px;
  width: 100%;
}

@media (width >= 768px) {
  .btn-light {
    height: 56px;
    width: 330px;
  }
}

.btn-cancel {
  background-color: #DF6565;
}

.btn-wrapper {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media (width >= 768px) {
  .btn-wrapper  {
    padding: 30px 80px;
    justify-content: space-between;
    flex-direction: row;
  }
}

@media (width >= 768px) {
  .light-wrapper {
    margin: 62px 43px 64px;
  }
}

h2 {
  text-align: center;
}

.object-date {
  font-size: 18px;
  line-height: 26px;
  margin: 0;
}

@media (width >= 768px) {
  .object-date {
    font-size: 22px;
    line-height: 30px;
  }
}

@media (width >= 1440px) {
  .object-date {
    font-size: 24px;
    line-height: 32px;
  }
}

.object-addr-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.object-addr {
  font-size: 18px;
  line-height: 24px;
  color: #199057;
  font-weight: 400;
}

@media (width >= 768px) {
  .object-addr {
    font-size: 18px;
    line-height: 24px;
    color: #199057;
  }
}

@media (width >= 1440px) {
  .object-addr {
    font-size: 18px;
    line-height: 24px;
    color: #199057;
  }
}

.object-time {
  font-size: 18px;
  line-height: 26px;
  margin: 0;
  margin-bottom: 8px;
}

@media (width >= 768px) {
  .object-time {
    font-size: 22px;
    line-height: 30px;
  }
}

@media (width >= 1440px) {
  .object-time {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 16px;
  }
}

.object-price-wrapper {
  border-top: 1px solid #6D6D6D;
  display: flex;
  justify-content: space-between;
}

.object-price {
  font-size: 18px;
  line-height: 26px;
  color: #199057;
  font-weight: 700;
}

@media (width >= 768px) {
  .object-price {
    font-size: 22px;
    line-height: 30px;
  }
}

@media (width >= 1440px) {
  .object-price {
    font-size: 30px;
    line-height: 40px;
  }
}

hr {
  color: #6D6D6D;
  height: 2px;
}

@media (width < 1100px) {
  .object-image {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.object-image img {
  width: 280px;
  height: 202px;
}

@media (width >= 768px) {
  .object-image img {
    width: 400px;
    height: 288px;
  }
}

@media (width >= 1440px) {
  .object-image img {
    width: 400px;
    height: 288px;
  }
}

.card-wrapper {
  display: flex;
  gap: 20px;
  align-items: center ;
}

.cards {
  margin-top: 22px;
}

.objects {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

@media (width >= 1600px) {
  .objects {
    justify-content: space-between;
    flex-direction: row;
  }
}

h2 {
  text-align: left;
  margin: 0 0 16px;
}

.card-text {
  margin: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media (width >= 768px) and (width < 1100px) {
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media (width < 1100px) {
  .form-field {
    width: 100%;
  }
}

@media (width >= 768px) {
  .objects {
    display: flex;
    justify-content: space-between;
  }
}

.success-message {
  color: green;
  font-weight: bold;
}

.error-message {
  color: red;
  font-weight: bold;
}
</style>