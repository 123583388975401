<template>
  <header class="header">
    <div class="container">
      <div class="logo">
        <router-link to="/">
          <img class="logo" src="@/assets/logo.svg" alt="ДомШеринг" />
        </router-link>
      </div>

      <button
          class="burger main-nav-toggle"
          type="button"
          :aria-label="isMenuOpen ? 'Закрыть меню' : 'Открыть меню'"
          :aria-expanded="isMenuOpen ? 'true' : 'false'"
          @click="toggleMenu"
      >
        <img
            :src="isMenuOpen ? require('@/assets/icon-cross.svg') : require('@/assets/icon-menu.svg')"
            alt="Menu icon"
            class="burger-toggle"
        />
      </button>

      <nav :class="['menu', 'main-nav', { open: isMenuOpen }]">
        <div class="menu-link main-nav-list">
          <router-link v-if="isLoggedIn" to="/profile">Мой профиль</router-link>
          <router-link v-if="isLoggedIn" to="/reservations">Мои бронирования</router-link>
          <router-link to="/">Объекты</router-link>
          <router-link to="/about">О сервисе</router-link>
          <router-link to="/contacts">Контакты</router-link>
          <button v-if="!isLoggedIn" class="btn-header-mobile" @click="login">Вход</button>
          <button v-else class="btn-header-mobile" @click="logout">Выход</button>
        </div>
      </nav>

      <button v-if="!isLoggedIn" class="btn btn-header" @click="login">
        Вход
        <svg class="user-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M4.92993 19.0706C5.50542 17.9937 6.3557 17.0465 7.41366 16.3022C8.47163 15.5579 9.70459 15.0451 11 14.8043C12.2954 14.5636 13.6235 14.6025 14.9 14.9174C16.1766 15.2322 17.3675 15.814 18.364 16.6162C19.3605 17.4183 20.133 18.4247 20.6256 19.5554" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>

      <button v-else class="btn btn-header" @click="logout">
        Выход
        <svg class="user-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M4.92993 19.0706C5.50542 17.9937 6.3557 17.0465 7.41366 16.3022C8.47163 15.5579 9.70459 15.0451 11 14.8043C12.2954 14.5636 13.6235 14.6025 14.9 14.9174C16.1766 15.2322 17.3675 15.814 18.364 16.6162C19.3605 17.4183 20.133 18.4247 20.6256 19.5554" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>
  </header>
</template>

<script>
export default {
  name: 'AppHeader',
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    }
  },
  data() {
    return {
      isMenuOpen: false
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    login() {
      this.$router.push('/login');
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/login');
    }
  }
}
</script>

<style scoped>
/* Шапка */
.header {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Hind Madurai', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.08px;
}

@media (width >= 768px) {
  .header  {
    padding: 20px;
  } 
}

/* Контейнер для выравнивания */

@media (width >= 768px) {
  .container  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
  } 
}

@media (width >= 810px) {
  .container  {
    margin: 0 20px;
  } 
}
@media (width >= 1440px) {
  .container  {
    margin: 0 80px;
  } 
}

/* Логотип */

@media (width < 768px) {
  .logo  {
    padding: 20px;
    display: flex;
    align-items: center;
  } 
}
.logo {
  width: 74px;
  height: 44px;
}

@media (width >= 768px) {
  .logo{
    width: 90px;
    height: 54px;
  } 
}

@media (width >= 1440px) {
  .logo {
    width: 106px;
    height: 64px;
  } 
}


/* Меню */
.menu {
  display: flex;
  justify-content: center;
  flex: 1;
  gap: 40px;
}




@media (width < 768px) {
  .menu {
    display: none;
  }

  .menu.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #199057;
    z-index: 10;
  }

  .menu a:hover {
    color: #fff;
  }
}


@media (width >= 1111px) {
  .menu {
    margin-left: 42px;
  } 
}

.menu a,
.menu button {
  color: #ffffff;
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  padding: 16px 0;
  border-bottom: 1px solid #ffffff;
}

@media (width >= 768px) {
  .menu a,
  .menu button {
    color: #333;
  }
}

.menu a:hover {
  color: #199057;
}

.menu-link {
  display: flex;
}

@media (width >= 768px) and (width < 1111px){
  .menu-link {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }
}

@media (width >= 1111px) {
  .menu-link {
    gap: 60px;
    margin: 0 30px;
  }
}



display: grid;
    grid-template-columns: 1fr 1fr 1fr;

/* Иконка пользователя */
.user-icon {
  width: 24px;
  height: 24px;
}

.btn {
  width: 126px;
  height: 32px;
  border-radius: 6px;
  font-size: 14px;
  padding: 16px 20px;
  gap: 10px;
}

@media (width >= 768px) {
  .btn {
    height: 44px;
    width: 138px;
    border-radius: 10px;
    font-size: 16px;
  } 
}

@media (width >= 1440px) {
  .btn {
    height: 56px;
    width: 148px;
    font-size: 20px;
  } 
}

@media (width >= 768px) {
  .btn-header-mobile {
    display: none;
  }
}

@media (width < 768px) {
  .btn-header-mobile {
    background-color: #199057;
    border: none;
    text-transform: uppercase;
  }
}

@media (width < 768px) {
  .btn-header {
    display: none;
  }
}

/* Меню-бургер */
@media (width < 768px) {
  .main-nav-toggle[aria-expanded="true"] + .main-nav-list {
    translate: 0 100%;
  }
}

  @media (width < 768px) {
    .main-nav-toggle[aria-expanded="true"] .burger-toggle {
      background-image: url("@/assets/icon-cross.svg");
    }
  }


@media (width < 768px) {
  .main-nav-list {
    width: 100%;
    background-color: #199057 ;
  }
}

@media (width < 768px) {
  .main-nav-list {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    list-style: none;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 10;
  }
}

@media (width < 768px) {
  .burger-toggle {
    display: block;
    width: 24px;
    height: 24px;
    background-image: url("@/assets/icon-menu.svg");
    background-repeat: no-repeat;
  }
}

@media (width < 768px) {
  .burger-toggle:hover {
    opacity: 0.6;
  }
}

@media (width < 768px) {
  .burger-toggle:active {
    opacity: 0.3;
  }
}

@media (width < 768px) {
  .main-nav-toggle {
    top: 23px;
    right: 20px;
    position: absolute;
    border: none;
    background-color: transparent;
    justify-self: end;
    z-index: 11;
    padding: 6px 20px;
  }
}

@media (width >= 768px) {
  .main-nav-toggle {
    display: none;
  }
}

.logout-icon {
  margin-left: 10px;
  cursor: pointer;
  fill: #fff; /* Цвет иконки */
}

.burger-toggle {
  width: 24px;
  height: 24px;
}

</style>


