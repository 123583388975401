<template>
  <div>
    <AppHeader />
    <div class="btn-wrapper">
      <button class="btn btn-light" @click="$router.go(-1)">
        <svg class="arrow-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
          <path fill="#199057" d="M9.57 5.18c.19 0 .38.07.53.22.29.29.29.77 0 1.06L4.56 12l5.54 5.54c.29.29.29.77 0 1.06-.29.29-.77.29-1.06 0l-6.07-6.07a.754.754 0 0 1 0-1.06L9.04 5.4c.15-.15.34-.22.53-.22Z" />
          <path fill="#199057" d="M3.67 11.25H20.5c.41 0 .75.34.75.75s-.34.75-.75.75H3.67c-.41 0-.75-.34-.75-.75s.34-.75.75-.75Z" />
        </svg>
        Назад
      </button>
      <button class="btn" @click="bookRoom">Забронировать</button>
      <div v-if="bookingMessage" :class="{'success-message': bookingSuccess, 'error-message': !bookingSuccess}">
        {{ bookingMessage }}
      </div>
    </div>

    <!-- Отображение объекта -->
    <div v-if="object">
      <div class="objects">
        <div class="objects-card">
          <h2>{{ object.nm }}</h2>
          <div v-if="startDate !== 'Дата не указана' && endDate !== 'Дата не указана'">
            <p class="object-date">{{ formattedDate }}</p>
          </div>
          <div v-else>
            <div class="form-field form-with-icon">
              <flat-pickr
                  v-model="selectedDateRange"
                  :config="datePickerConfig"
                  placeholder="Выберите даты"
                  class="flat-pickr-input"
              />
              <div v-if="showDateError" class="warning">
                <p class="error-message">Пожалуйста, выберите период</p>
              </div>
            </div>
          </div>
          <div class="object-addr-wrapper">
            <p class="object-addr">{{ object.location }}</p>
          </div>
          <p class="object-time">{{ "Заезд в: " + time1 }}</p>
          <p class="object-time">{{ "Выезд до: " + time2 }}</p>
          <div class="object-price-wrapper">
            <p class="object-price"><span>Сумма</span></p>
            <p class="object-price">{{ totalPrice }} р.</p>
          </div>
        </div>
        <div class="object-image">
          <img :src="imageSrc" alt="Card" />
        </div>
      </div>
    </div>

    <!-- Отображение номеров -->
    <div v-if="object">
      <div class="objects">
        <div class="card">
          <h2>Описание</h2>
          <h3>{{ object.nm }}</h3>
          <div class="wrapper">
            <div class="card-wrapper">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none">
                <path fill="#199057" fill-rule="evenodd" d="M9.566 2.853a3.235 3.235 0 1 0 0 6.47 3.235 3.235 0 0 0 0-6.47ZM3.978 6.088a5.588 5.588 0 1 1 11.176 0 5.588 5.588 0 0 1-11.176 0Z" clip-rule="evenodd"/>
                <path fill="#199057" fill-rule="evenodd" d="M3.68 13.946a9.986 9.986 0 0 1 15.395 5.018 1.177 1.177 0 0 1-1.12 1.536H1.176a1.176 1.176 0 0 1-1.12-1.536 9.986 9.986 0 0 1 3.624-5.018Zm5.886.434a7.633 7.633 0 0 0-6.582 3.767h13.163a7.633 7.633 0 0 0-6.581-3.767Z" clip-rule="evenodd"/>
              </svg>
              <p class="card-text">{{ object.max_guests }} чел.</p>
            </div>
            <div class="card-wrapper card-wrapper-gap-1">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" fill="none">
                <path stroke="#199057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.538 21.5h16.924c.85 0 1.538-.689 1.538-1.538V3.038c0-.85-.689-1.538-1.538-1.538H2.538C1.688 1.5 1 2.189 1 3.038v16.924c0 .85.689 1.538 1.538 1.538ZM1 10.73 9.462 1.5M21 10.73 12.54 1.5M1 12.269l8.462 9.23M21 12.269l-8.461 9.23"/>
              </svg>
              <p class="card-text">{{ object.square }} м²</p>
            </div>
            <div class="card-wrapper card-wrapper-gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" fill="none">
                <path stroke="#199057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.308 6.885h12.308a.77.77 0 0 1 .769.769v6.923a3.077 3.077 0 0 1-3.077 3.077H5.616a3.077 3.077 0 0 1-3.077-3.077V7.654a.77.77 0 0 1 .769-.77v0ZM1 21.5h20M16.385 8.423h1.538a3.077 3.077 0 1 1 0 6.154h-1.538M4.846 1.5v1.538M12.539 1.5v1.538M8.692 1.5v1.538"/>
              </svg>
              <p class="card-text">{{ object.with_breakfast === '1' ? 'Завтрак включен' : 'Завтрак не включен' }}</p>
            </div>
            <div class="card-wrapper card-wrapper-gap-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="23" fill="none">
                <path stroke="#199057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 16.5c1.105 0 2-1.343 2-3s-.895-3-2-3-2 1.343-2 3 .895 3 2 3ZM9 7.5c1.105 0 2-1.343 2-3s-.895-3-2-3-2 1.343-2 3 .895 3 2 3ZM19 7.5c1.105 0 2-1.343 2-3s-.895-3-2-3-2 1.343-2 3 .895 3 2 3ZM25 16.5c1.105 0 2-1.343 2-3s-.895-3-2-3-2 1.343-2 3 .895 3 2 3ZM20 17.5c0 2.76-3.24 4-6 4s-6-1.24-6-4 2-7 6-7 6 4.24 6 7Z"/>
              </svg>
              <p class="card-text">{{ object.with_pets === '1' ? 'Можно с животными' : 'Нельзя с животными' }}</p>
            </div>
            <div class="card-wrapper card-wrapper-gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" fill="none">
                <path stroke="#199057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.23 10.731h-6.153a.77.77 0 0 0-.77.77v9.23c0 .425.345.77.77.77h6.154a.77.77 0 0 0 .769-.77v-9.23a.77.77 0 0 0-.77-.77ZM20.23 1.5h-6.153a.77.77 0 0 0-.77.77v3.092c0 .424.345.769.77.769h6.154A.77.77 0 0 0 21 5.36V2.27a.77.77 0 0 0-.77-.769ZM7.923 1.5H1.77A.77.77 0 0 0 1 2.27v9.23c0 .425.344.77.77.77h6.153a.77.77 0 0 0 .77-.77V2.27a.77.77 0 0 0-.77-.77ZM7.923 16.87H1.77a.77.77 0 0 0-.769.769v3.092c0 .425.344.77.77.77h6.153a.77.77 0 0 0 .77-.77V17.64a.77.77 0 0 0-.77-.77Z"/>
              </svg>
              <p class="card-text">{{ object.num_of_rooms }} комнаты</p>
            </div>
          </div>
        </div>
        <!-- Основной гость
        <div class="guest">
          <h2>Основной гость</h2>
          <div class="form-field-wrapper">
            <div class="form-field">
              <p class="text">ФИО</p>
              <input type="text" />
            </div>
            <div class="form-field">
              <p class="text">Телефон</p>
              <input type="number" />
            </div>
            <div class="form-field">
              <p class="text">Email</p>
              <input type="text" />
            </div>
          </div>
        </div>-->
      </div>

    </div>

    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './AppHeader.vue';
import AppFooter from './AppFooter.vue';
import axios from 'axios';
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Russian } from 'flatpickr/dist/l10n/ru.js';

// Функция для преобразования строки "DD.MM.YYYY" в формат "YYYY-MM-DD"
function convertDate(dateStr) {
  const [day, month, year] = dateStr.split('.');
  return `${year}-${month}-${day}`;
}


/* function formatDateTime(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');  // Добавляем ведущий 0 для месяца
  const day = String(date.getDate()).padStart(2, '0');         // Добавляем ведущий 0 для дня
  const hours = String(date.getHours()).padStart(2, '0');      // Добавляем ведущий 0 для часов
  const minutes = String(date.getMinutes()).padStart(2, '0');  // Добавляем ведущий 0 для минут
  const seconds = String(date.getSeconds()).padStart(2, '0');  // Добавляем ведущий 0 для секунд

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}*/

function formatUnixTime(date) {
  return Math.floor(date.getTime() / 1000); // Преобразуем в секунды и округляем до целого
}

export default {
  name: 'ReservationsCard',
  components: {
    AppHeader,
    AppFooter,
    flatPickr
  },
  props: {
    id: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      object: null, // Объект из API
      time1: '16:00', // Время заезда по умолчанию
      time2: '12:00', // Время выезда по умолчанию
      bookingMessage: '',  // Сообщение о результате бронирования
      bookingSuccess: false, // Статус бронирования
      selectedDateRange: [],  // Для хранения выбранных дат
      datePickerConfig: {
        mode: 'range',
        dateFormat: 'd.m.Y',
        closeOnSelect: false,
        locale: Russian // Здесь подключаем локаль
      },
      showDateError: false
    };
  },
  computed: {
    // Форматирование дат заезда и выезда
    startDate() {
      // Если есть выбранный диапазон и он строка, разделяем его на части
      if (this.selectedDateRange && typeof this.selectedDateRange === 'string') {
        let dateRange = this.selectedDateRange.split(' — ');
        return dateRange[0] ? dateRange[0].trim() : 'Дата не указана';
      }
      // Если даты переданы через маршрут, возвращаем их
      return this.$route.query.startDate || 'Дата не указана';
    },
    endDate() {
      // Если есть выбранный диапазон и он строка, разделяем его на части
      if (this.selectedDateRange && typeof this.selectedDateRange === 'string') {
        let dateRange = this.selectedDateRange.split(' — ');
        return dateRange[1] ? dateRange[1].trim() : 'Дата не указана';
      }
      // Если даты переданы через маршрут, возвращаем их
      return this.$route.query.endDate || 'Дата не указана';
    },
    guestCount() {
      return this.$route.query.guestCount || '1';
    },

    totalPrice() {
      if (!this.startDate || !this.endDate || this.startDate === 'Дата не указана' || this.endDate === 'Дата не указана') {
        return 0;
      }
      const startDate = new Date(convertDate(this.startDate));
      const endDate = new Date(convertDate(this.endDate));

      // Если даты некорректны, возвращаем 0
      if (isNaN(startDate) || isNaN(endDate)) {
        return 0;
      }

      const totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
      return this.object ? this.object.price * totalDays : 0;
    },
    formattedDate() {
      if (this.startDate !== 'Дата не указана' && this.endDate !== 'Дата не указана') {
        return `${this.startDate} - ${this.endDate}`;
      } else {
        return 'Дата не указана';
      }
    },
    imageSrc() {
      return this.object && this.object.file_id
          ? `https://лк.домшеринг.рф/file/${this.object.file_id}/${this.object.file_name}`
          : 'https://via.placeholder.com/200'; // Заглушка на случай, если изображение отсутствует
    }
  },
  methods: {
    async fetchObject() {
      try {
        const response = await axios.get(`https://лк.домшеринг.рф/api/vrc?filters[id-e]=${this.id}`);
        this.object = response.data.data[0]; // Заполняем объект данными из API
      } catch (error) {
        console.error('Ошибка при загрузке объекта:', error);
      }
    },
    async bookRoom() {
      try {
        const token = this.$store.getters.token; // Получаем токен из Vuex
        const userId = this.$store.getters.userId;   // Получаем пользователя из Vuex

        if (!userId) {
          console.log(token);
          console.log(userId);
          console.error('Пользователь не авторизован');
          this.$router.push({ name: 'NotAuthorized' });
          return;
        }
        console.log(userId);
        const roomId = this.object ? this.object.id : null; // Получаем ID номера

        const startDateRaw = this.$route.query.startDate || this.startDate;
        const endDateRaw = this.$route.query.endDate || this.endDate;

        const startDateStr = convertDate(startDateRaw);
        const endDateStr = convertDate(endDateRaw);

        const startDate = new Date(startDateStr);
        const endDate = new Date(endDateStr);

        if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
          this.bookingMessage = 'Некорректные даты бронирования';
          this.bookingSuccess = false;
          return;
        }

        const totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
        const total_price = this.object ? parseFloat(this.object.price) * totalDays : 0;

        if (total_price <= 0) {
          this.bookingMessage = 'Итоговая цена бронирования не может быть нулевой или отрицательной';
          this.bookingSuccess = false;
          return;
        }

        const raw = JSON.stringify({
          "usr_id": userId,
          "cot_id": this.object.cot_id,
          "rom_id": roomId,
          "sts_id": "pend",
          "start_dt": formatUnixTime(startDate),  // Преобразуем дату в нужный формат 'YYYY-MM-DD HH24:MI:SS'
          "end_dt": formatUnixTime(endDate),      // Преобразуем дату в нужный формат 'YYYY-MM-DD HH24:MI:SS'
          "total_price": total_price,
          "num_of_guests": this.guestCount
        });
        console.log(formatUnixTime(startDate));

        const myHeaders = new Headers();
        myHeaders.append("token", token);
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };

        const response = await fetch("https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/res", requestOptions);
        const result = await response.json();

        const currentUnixTime = Math.floor(Date.now() / 1000);

        const raw2 = JSON.stringify({
          data_tx: "новое бронирование",
          evt_id: "booking",
          e: 1,
          nm: "новое бронирование",
          reason: "",
          usr_id: userId,
          to_usr_id: this.object.own_usr_id,
          cre_dt: currentUnixTime
        });

        const requestOptions2 = {
          method: "POST",
          headers: myHeaders,
          body: raw2,
          redirect: "follow"
        };

        const response2 = await fetch("https://лк.домшеринг.рф/api/eve", requestOptions2);
        if (response2.ok) { console.log('ok'); }

        if (response.ok) {
          // Бронирование успешно
          this.bookingMessage = 'Бронирование успешно. Перенаправляем на страницу бронирований...';
          this.bookingSuccess = true;
          setTimeout(() => {
            this.$router.push({ name: 'ReservationsPage' }); // Перенаправление на список бронирований через 2 секунды
          }, 2000);
        } else {
          // Ошибка бронирования
          this.bookingMessage = `Ошибка бронирования: ${result.errors[0].title}`;
          this.bookingSuccess = false;
        }
      } catch (error) {
        console.error('Ошибка при бронировании:', error);
        this.bookingMessage = 'Произошла ошибка при бронировании.';
        this.bookingSuccess = false;
      }
    }
  },
  mounted() {
    this.fetchObject(); // Получаем объект при монтировании компонента

  },
};
</script>

<style scoped>
  .btn {
    font-size: 14px;
    width: 100%;
  }

  @media (width >= 768px) {
  .btn {
    font-size: 16px;
    width: 330px;
  }
}

  .btn-light {
    gap: 12px;
    width: 160px;
    height: 48px;
    width: 100%;
  }

  @media (width >= 768px) {
  .btn-light {
    height: 56px;
    width: 330px;
  }
}

.btn-wrapper {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media (width >= 768px) {
  .btn-wrapper  {
    padding: 30px 80px;
    justify-content: space-between;
    flex-direction: row;
  }
}

  @media (width >= 768px) {
  .light-wrapper {
    margin: 62px 43px 64px;
  }
}

h2 {
  text-align: center;
}

.object-date {
  font-size: 18px;
  line-height: 26px;
  margin: 0;
}

@media (width >= 768px) {
  .object-date {
    font-size: 22px;
    line-height: 30px;
  }
}

@media (width >= 1440px) {
  .object-date {
    font-size: 24px;
    line-height: 32px;
  }
}

.object-addr-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.object-addr {
  font-size: 18px;
  line-height: 24px;
  color: #199057;
  font-weight: 400;
}

@media (width >= 768px) {
  .object-addr {
    font-size: 18px;
    line-height: 24px;
    color: #199057;
  }
}

@media (width >= 1440px) {
  .object-addr {
    font-size: 18px;
    line-height: 24px;
    color: #199057;
  }
}

.object-time {
  font-size: 18px;
  line-height: 26px;
  margin: 0;
  margin-bottom: 8px;
}

@media (width >= 768px) {
  .object-time {
    font-size: 22px;
    line-height: 30px;
  }
}

@media (width >= 1440px) {
  .object-time {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 16px;
  }
}

.object-price-wrapper {
  border-top: 1px solid #6D6D6D;
  display: flex;
  justify-content: space-between;
}

.object-price {
  font-size: 18px;
  line-height: 26px;
  color: #199057;
  font-weight: 700;
}

@media (width >= 768px) {
  .object-price {
    font-size: 22px;
    line-height: 30px;
  }
}

@media (width >= 1440px) {
  .object-price {
    font-size: 30px;
    line-height: 40px;
  }
}

hr {
  color: #6D6D6D;
  height: 2px;
}

@media (width < 1100px) {
  .object-image {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.object-image img {
  width: 280px;
  height: 202px;
}

@media (width >= 768px) {
  .object-image img {
    width: 400px;
    height: 288px;
  }
}

@media (width >= 1440px) {
  .object-image img {
    width: 400px;
    height: 288px;
  }
}

.card-wrapper {
  display: flex;
  gap: 20px;
  align-items: center ;
}

.cards {
  margin-top: 22px;
}

.objects {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

@media (width >= 1100px) {
  .objects {
    justify-content: space-between;
    flex-direction: row;
  }
}

h2 {
  text-align: left;
  margin: 0 0 16px;
}

.card-text {
  margin: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media (width >= 768px) and (width < 1100px) {
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}


@media (width >= 768px) {
  .objects {
    display: flex;
    justify-content: space-between;
  }
}

  .success-message {
    color: green;
    font-weight: bold;
  }

  .error-message {
    color: red;
    font-weight: bold;
  }

  .text {
  font-size: 14px;
  font-weight: 400;
  color: #686868;
  margin: 0;
}

/* Общие стили для полей формы */

.form-field-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 504px;
}

@media (width < 1100px) {
  .form-field {
    width: 100%;
  }
}

.form-field input {
  padding: 18px 20px;
  border: 1px solid #D5D6DE;
  border-radius: 10px;
  background: #FFFFFF;
  font-size: 16px;
}

@media (width < 768px) {
  .form-field input {
    font-size: 12px;
  }
}
</style>