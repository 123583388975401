<template>
  <div>
    <AppHeader />
    <div class="hero">
      <div class="hero-wrapper">
        <h1>Бронируйте объекты</h1>
        <p class="description">на ДомШеринг</p>
        <img class="hero-image-key" src="@/assets/about_key.svg" alt="" />
      </div>
    </div>
    <div class="hero-image">
      <img class="hero-image-car" src="@/assets/about_car.svg" alt="" />
      <img class="hero-image-camping" src="@/assets/about_camping.svg" alt="" />
    </div>
    <div class="light-wrapper">
      <ul class="house-card">
        <li class="house-item house-item-cottage">
          <p class="house-description">Коттеджи</p>
        </li>
        <li class="house-item house-item-glamping">
          <p class="house-description">Глемпинги</p>
        </li>
        <li class="house-item house-item-camping">
          <p class="house-description">Кемпинги</p>
        </li>
      </ul>
    </div>
    <div class="join">
      <h2>Присоединяйтесь к Домшеринг</h2>
      <p class="h3-description"><span style="font-weight: 700; color: #199057">ДомШеринг</span> — новый бесплатный онлайн-сервис для поиска мест отдыха с проживанием, созданный для вашего комфорта. Мы предлагаем уникальную возможность подбора объектов не только по типу жилья, но и по активностям, которые находятся рядом. Хотите кататься на горных лыжах, попробовать вейксерфинг, погулять по лесу или расслабиться в бане? Наш сервис поможет вам найти идеальное место, где всё это доступно. В базе представлены коттеджи, глемпинги, кемпинги и другие типы жилья, удовлетворяющие самые разные предпочтения. С <span style="font-weight: 700; color: #199057">ДомШеринг</span> вы легко и быстро подберете лучшее место для отдыха, которое соответствует вашим желаниям и интересам!</p>
        <div class="dark-wrapper presentation-dark-wrapper">
      <!-- Внешняя ссылка на презентацию -->
          <a href="https://домшеринг.рф/anketa/presentation.pdf" target="_blank" class="btn btn-light" download>Скачать презентацию</a>
        </div>
    </div>
    

    <div class="affairs">
      <h2>Что нужно сделать?</h2>
      <ul class="affairs-list">
        <li class="affairs-item">
          <p class="affairs-number">1</p>
          <p class="affairs-text">Зарегистрируйтесь</p>
        </li>
        <li class="affairs-item">
          <p class="affairs-number">2</p>
          <p class="affairs-text">Выберите понравившийся объект</p>
        </li>
        <li class="affairs-item">
          <p class="affairs-number">3</p>
          <p class="affairs-text">Забронируйте на нужные даты</p>
        </li>
      </ul>
    </div>
    <div class="dark-wrapper dark-wrapper-arrow">
      <!-- Кнопка перехода на страницу выбора объектов -->
      <button class="btn btn-light" @click="navigateToHome">Перейти к выбору объектов</button>
    </div>


    <!-- Всплывающее окно -->
    <div v-if="showModal" class="modal-overlay" @click="closeModal">
      <div class="modal-content" @click.stop>
        <h3>Внимание</h3>
        <p>
          На текущем этапе саморегистрация клиентов отключена. Для отправки заявки заполните
          форму обратной связи ниже или позвоните по телефону +7-911-111-11-11.
        </p>
        <button class="btn btn-light" @click="closeModal">Закрыть</button>
      </div>
    </div>

    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './AppHeader.vue';
import AppFooter from './AppFooter.vue';

export default {
  name: 'AboutPage',
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      showModal: false // Управляет видимостью модального окна
    };
  },
  methods: {
    closeModal() {
      this.showModal = false; // Закрывает модальное окно
    },
    navigateToHome() {
      this.$router.push({ name: 'Home' }).then(() => {
        // Явно прокручиваем страницу вверх после перехода
        window.scrollTo(0, 0);
      });
    }
  }
};
</script>

<style scoped>

.hero {
  padding: 50px 20px 0;
  flex-direction: column;
}

@media (width >= 768px) {
  .hero {
    padding: 80px 70px 0 44px;
    flex-direction: row;
  } 
}

@media (width >= 1440px) {
  .hero {
    padding: 113px 80px 0;
  } 
}

.hero-wrapper {
  position: relative;
}

@media (width < 768px) {
  .hero-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  } 
}
.hero-image {
  display: flex;
}

@media (width >= 768px) {
  .hero-image {
    justify-content: space-between;
    margin-top: -60px;
    padding: 0 80px;
  } 
}

.hero-image-key {
  position: absolute;
  right: 18px;
  top: 23px;
  width: 70px;
  height: 90px;
}

@media (width >= 768px) {
  .hero-image-key {
    top: -10px;
    right: -53px;
    width: 90px;
    height: 117px;
  } 
}

@media (width >= 1440px) {
  .hero-image-key {
    width: 122px;
    height: 160px;
  } 
}

.hero-image-car {
  width: 220px;
  height: 122px;
}

@media (width >= 950px) {
  .hero-image-car {
    width: 340px;
    height: 190px;
  } 
}

@media (width >= 1440px) {
  .hero-image-car {
    width: 465px;
    height: 258px;
  } 
}

@media (width < 768px) {
  .hero-image-camping {
    display: none;
  } 
}

.hero-image-camping {
  width: 280px;
  height: 175px;
}

@media (width >= 950px) {
  .hero-image-camping {
    width: 460px;
    height: 288px;
  } 
}

@media (width >= 1440px) {
  .hero-image-camping {
    width: 613px;
    height: 385px;
  } 
}

img {
  margin-top: auto;
}

@media (width < 768px) {
  h2 {
    margin: 0;
  }
}

.house-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  padding: 20px;
  margin: 0;
}

@media (width >= 768px) {
  .house-card {
    flex-direction: row;
    padding: 30px;
  } 
}

.house-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  background-color: #F5F5F7;
  background-repeat: no-repeat;
  background-position: top 20px center;
}

@media (width >= 768px) {
  .house-item {
    width: 100%;
  }
}

@media (width >= 1440px) {
  .house-item {
    padding: 30px;
  }
}
  .house-item-cottage {
    background-image: url("@/assets/about_cottage.svg");
    background-size: 40px 40px;
  }

@media (width >= 1440px) {
  .house-item-cottage {
    background-size: 54px 54px;
  }
}

  .house-item-glamping {
    background-image: url("@/assets/about_glamping.svg");
    background-size: 50px 40px;
  }

@media (width >= 1440px) {
  .house-item-glamping {
    background-size: 68px 54px;
  }
}

  .house-item-camping {
    background-image: url("@/assets/about_campings.svg");
    background-size: 40px 40px;
  }

  @media (width >= 1440px) {
  .house-item-camping {
    background-size: 54px 54px;
  }
}


.house-description {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 50px;
}

@media (width >= 768px) {
  .house-description {
    font-size: 20px;
  }
}

@media (width >= 1440px) {
  .house-description {
    font-size: 24px;
  }
}

@media (width < 768px) {
  .light-wrapper {
    margin: -20px 20px 50px;
  }
}

.affairs {
  padding: 0 20px 30px;
}

@media (width >= 768px) {
  .affairs {
    padding: 40px;
  } 
}

.dark-wrapper-arrow {
  position: relative; 
  margin: 80px auto 30px;
  margin: 30px auto;
  width: 70%;
}

@media (width >= 768px) {
  .dark-wrapper-arrow {
    width: 468px;
    margin: 100px auto 50px;
  } 
}

@media (width >= 1440px) {
  .dark-wrapper-arrow {
    width: 468px;
    margin: 150px auto 50px;
  } 
}

@media (width >= 768px) {
  .dark-wrapper-arrow::before {
    width: 73px;
    height: 160px;
    z-index: 10;
    bottom: 91px;
    content: '';
    background-image: url("@/assets/arrow.svg");
    position: absolute;
    background-size: cover;
  } 
}

@media (width >= 1440px) {
  .dark-wrapper::before {
    width: 90px;
    height: 200px;
  } 
}

.affairs-list {
  display: flex;
  justify-content: space-evenly;
  position: relative;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

@media (width >= 768px) {
  .affairs-list {
    flex-direction: row;
  } 
}
.affairs-list::after {
  content: '';
  position: absolute;
  height: 4px;
  background: #199057;
}

@media (width < 768px) {
  .affairs-list::after {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    transform: rotate(-90deg);
    margin: auto;
    width: 80%;
  }
}

@media (width >= 410px) {
  .affairs-list::after {
    width: 60%;
  } 
}

@media (width >= 536px) {
  .affairs-list::after {
    width: 45%;
  } 
}

@media (width >= 696px) {
  .affairs-list::after {
    width: 34%;
  } 
}

@media (width >= 768px) {
  .affairs-list::after {
    top: 55px;
    width: 62%;
  } 
}

@media (width >= 1440px) {
  .affairs-list::after {
    top: 60px;
    width: 61%;
  } 
}

@media (width >= 1440px) {
  .affairs-list {
    padding: 0;
  } 
}

.affairs-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  padding: 0 30px;
  text-align: center;
}

.affairs-number {
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  border: 4px solid #199057;
  border-radius: 50%;
  color: #222222;
  font-size: 26px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (width >= 1440px) {
  .affairs-number {
    width: 60px;
    height: 60px;
  } 
}

.affairs-text {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  background-color: #FDFEFA;
}

@media (width >= 768px) {
  .affairs-text {
    font-size: 18px;
  } 
}

@media (width >= 768px) {
  .affairs-text {
    font-size: 20px;
  } 
}

.btn {
  width: 88%;
  height: 100%;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  text-align: center;
}

.modal-content h3 {
  margin-bottom: 15px;
}

.modal-content p {
  margin-bottom: 20px;
}

.modal-content .btn {
  margin-top: 10px;
}

.join {
  padding: 0 20px 30px;
}

@media (width >= 768px) {
  .join {
    padding: 40px;
  } 
}

h2 {
  margin: 0 0 25px;
}

.presentation-dark-wrapper {
  position: relative; 
  margin: 30px auto;
  width: 80%;
}

@media (width >= 768px) {
  .presentation-dark-wrapper {
    width: 468px;
    margin: 50px auto 0;
  } 
}

@media (width >= 1440px) {
  .presentation-dark-wrapper {
    width: 468px;
    margin: 70px auto 0;
  } 
}

.presentation-dark-wrapper a {
  text-decoration: none;
  text-align: center;
}

</style>