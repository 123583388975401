import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Импорт маршрутизатора
import store from './store'; // Импорт Vuex Store

const app = createApp(App);

app.use(router);  // Подключаем маршрутизатор
app.use(store);   // Подключаем store

app.mount('#app');  // Монтируем приложение