<template>
  <div class="form-wrapper">
    <div class="form">
      <p class="title">Восстановление пароля</p>
      <div class="form-field-wrapper">
        <div class="form-field">
          <p class="text">Email</p>
          <input type="email" />
        </div>
      </div>
      <div class="btn-wrapper">
        <button class="btn">Сбросить пароль</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.form-wrapper {
  display: flex;
  background-color: #FAFDFC ;
  width: 100%;
  justify-content: center;
}

@media (width < 768px) {
  .form-wrapper {
    flex-direction: column;
  }
}

.form {
  background-color: #FAFDFC;
  padding: 0 20px;
}

@media (width >= 768px) {
  .form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (width >= 768px) and (width < 1280px) {
  .form {
    padding: 50px 98px;
    border-radius: 10px;
    top: 24%;
    position: absolute;
  }
}

.title {
  font-size: 22px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 19px;
  text-align: center;
}

@media (width >= 768px) {
  .title {
    font-size: 36px;
  }
}

.text {
  font-size: 14px;
  font-weight: 400;
  color: #686868;
  margin: 0;
}

@media (width >= 768px) {
  .text-password {
    font-size: 16px;
  }
}

.text-password {
  color: #A3A3A3;
  font-size: 12px;
  font-weight: 300;
  text-align: right;
  margin: 0;
}


@media (width >= 768px) {
  .text-password {
    font-size: 14px;
  }
}

/* Общие стили для полей формы */

.form-field-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 504px;
}

@media (width < 768px) {
  .form-field {
    width: 100%;
  }
}

.form-field input {
  padding: 18px 20px;
  border: 1px solid #D5D6DE;
  border-radius: 10px;
  background: #FFFFFF;
  font-size: 16px;
}

@media (width < 768px) {
  .form-field input {
    font-size: 12px;
  }
}

/* Кнопки */

.btn-wrapper {
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.btn {
  width: 100%;
  font-size: 16px;
  height: 52px;
}

@media (width >= 768px) {
  .logo {
    font-size: 20px;
    height: 56px;
  }
}

.error-message {
  color: red;
  margin: 10px 0;
}
</style>