<template>
    <div class="logo">
      <router-link to="/">
        <img class="logo" src="@/assets/logo.svg" alt="ДомШеринг" />
      </router-link>
    </div>
    <div class="wrapper">
      <LoginList />
      <picture>
        <source media="(min-width: 1600px)" srcset="@/assets/login.jpg" width="900"
          height="880">
        <source media="(min-width: 1280px)" srcset="@/assets/login-1280.jpg" width="580"
          height="880">
        <img class="login-picture" src="@/assets/login-768.jpg" height="700">
      </picture>
    </div>
</template>

<script>
import LoginList from './LoginList.vue';

export default {
  name: 'LoginPage',
  components: {
    LoginList
  }
}
</script>

<style scoped>

.logo {
  padding: 10px;
  width: 80px;
}

@media (width >= 768px) {
  .logo {
    width: 110px;
  }
}


@media (width < 1280px) {
  .logo {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }
}

@media (width >= 1280px) {
  .logo {
    position: absolute;
  }
}

.wrapper-logo {
  padding: 20px;
}

.wrapper {
  display: flex;
  align-items: center;
}

@media (width < 768px) {
  .wrapper {
    padding: 0 0 40px;
  }
}

@media (width < 1280px) {
  .wrapper {
    flex-direction: column;
    position: relative;
  }
}

@media (width < 768px) {
  .login-picture {
    display: none;
  }
}

@media (width < 1280px) {
  .login-picture {
    width: 100%;
  }
}

@media (width >= 1280px) and (width < 1600px){
  .login-picture {
    width: 580px;
    height: 880px;
  }
}

</style>