import { createRouter, createWebHistory } from 'vue-router';
import MainPage from '@/components/MainPage.vue';
import AboutPage from '@/components/AboutPage.vue';
import ContactsPage from '@/components/ContactsPage.vue';
import NotAuthorized from '@/components/NotAuthorized.vue';
import Options from '@/components/Options.vue';
import ViewCard from '@/components/ViewCard.vue';
import PhotosBlock from '@/components/PhotosBlock.vue';
import LoginPage from '@/components/LoginPage.vue';
import LoginList from '@/components/LoginList.vue';
import ReservationsPage from '@/components/ReservationsPage.vue';
import ReservationsList from '@/components/ReservationsList.vue';
import ReservationsCard from '@/components/ReservationsCard.vue';
import RegistrationPage from '@/components/RegistrationPage.vue';
import RegistrationList from '@/components/RegistrationList.vue';
import PasswordPage from '@/components/PasswordPage.vue';
import PasswordList from '@/components/PasswordList.vue';
import ProfilePage from '@/components/ProfilePage.vue';
import ComfortList from '@/components/ComfortList.vue';
import BookingInfo from '@/components/BookingInfo.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: MainPage
  },
  {
    path: '/about',
    name: 'About',
    component: AboutPage
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: ContactsPage
  },
  {
    path: '/not_authorized',
    name: 'NotAuthorized',
    component: NotAuthorized 
  },
  {
    path: '/options',
    name: 'Options',
    component: Options
  },
  {
    path: '/view_card',
    name: 'ViewCard',
    component: ViewCard
  },
  {
    path: '/object/:id',  // Динамический маршрут для страницы объекта
    name: 'ViewCard',
    component: ViewCard,
    props: true  // Пробрасываем параметры маршрута как props в компонент
  },
  {
    path: '/photos_block',
    name: 'PhotosBlock',
    component: PhotosBlock
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    path: '/login_list',
    name: 'LoginList',
    component: LoginList
  },
  {
    path: '/reservations',
    name: 'ReservationsPage',
    component: ReservationsPage
  },
  {
    path: '/reservations_list',
    name: 'ReservationsList',
    component: ReservationsList
  },
  {
    path: '/profile',
    name: 'ProfilePage',
    component: ProfilePage
  },
  {
    path: '/registration_list',
    name: 'RegistrationList',
    component: RegistrationList
  },
  {
    path: '/registration',
    name: 'RegistrationPage',
    component: RegistrationPage
  },
  {
    path: '/password_list',
    name: 'PasswordList',
    component: PasswordList
  },
  {
    path: '/password',
    name: 'PasswordPage',
    component: PasswordPage
  },
  {
    path: '/reservations_card/:id',
    name: 'ReservationsCard',
    component: ReservationsCard,
    props: true
  },
  {
    path: '/bookinginfo/:id',
    name: 'BookingInfo',
    component: BookingInfo,
    props: true
  },
  {
    path: '/comfort_list',
    name: 'ComfortList',
    component: ComfortList
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;