<template>
  <div class="form-wrapper">
    <div class="form">
      <p class="title">Регистрация</p>
      <div class="form-field-wrapper">
        <div class="form-field">
          <p class="text">Имя</p>
          <input
              type="text"
              v-model="name"
              placeholder="Введите ваше имя"
              :class="{ 'input-error': !isNameValid && showErrors }"
          />
          <p v-if="!isNameValid && showErrors" class="error-message">Имя обязательно для заполнения.</p>
        </div>
        <div class="form-field">
          <p class="text">Email</p>
          <input
              type="email"
              v-model="email"
              placeholder="Введите ваш email"
              :class="{ 'input-error': !isEmailValid && showErrors }"
          />
          <p v-if="!isEmailValid && showErrors" class="error-message">Введите корректный email.</p>
        </div>
        <div class="form-field">
          <p class="text">Телефон</p>
          <input
              type="text"
              v-model="phone"
              placeholder="Введите ваш телефон"
              :class="{ 'input-error': !isPhoneValid && showErrors }"
          />
          <p v-if="!isPhoneValid && showErrors" class="error-message">Введите корректный телефон в формате +7XXXXXXXXXX.</p>
        </div>
        <div class="form-field">
          <p class="text">Пароль</p>
          <input
              type="password"
              v-model="password"
              placeholder="Введите пароль"
              :class="{ 'input-error': !isPasswordValid && showErrors }"
          />
          <p v-if="!isPasswordValid && showErrors" class="error-message">Пароль обязателен для заполнения.</p>
        </div>
        <!-- Сообщение об ошибке, если есть -->
        <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
      </div>
      <div class="btn-wrapper">
        <button class="btn" @click="registerUser">Зарегистрироваться</button>
        <button class="btn btn-light" @click="$router.push({ name: 'LoginPage' })">Войти</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: '',        // Имя пользователя
      email: '',       // Email пользователя
      phone: '',       // Телефон пользователя
      password: '',    // Пароль пользователя
      errorMessage: '',// Сообщение об ошибке
      showErrors: false // Флаг для отображения ошибок валидации
    };
  },
  computed: {
    // Валидация имени
    isNameValid() {
      return this.name.trim() !== '';
    },
    // Валидация email
    isEmailValid() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(this.email);
    },
    // Валидация телефона
    isPhoneValid() {
      const phoneRegex = /^\+7\d{10}$/;
      return phoneRegex.test(this.phone);
    },
    // Валидация пароля
    isPasswordValid() {
      return this.password.trim() !== '';
    },
    // Общая валидация формы
    isFormValid() {
      return this.isNameValid && this.isEmailValid && this.isPhoneValid && this.isPasswordValid;
    }
  },
  methods: {
    async registerUser() {
      // Проверяем, есть ли ошибки валидации
      if (!this.isFormValid) {
        this.showErrors = true; // Показать ошибки, если есть
        return;
      }

      // Очистка предыдущего сообщения об ошибке
      this.errorMessage = '';

      try {
        // Формируем тело запроса
        const raw = JSON.stringify({
          mail: this.email,
          login: this.email,
          phone: this.phone,
          nm: this.name,
          grp_id: "buy",
          pass: this.password, // Пароль
          e: 1
        });

        // Создаем заголовки и параметры запроса
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };

        // Выполняем запрос к серверу
        const response = await fetch("https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/usr", requestOptions);
        const result = await response.json();

        // Если запрос успешен, перенаправляем на страницу входа
        if (response.ok) {
          this.$router.push({ name: 'LoginPage' });
        } else {
          // Выводим сообщение об ошибке, если есть проблемы
          this.errorMessage = result.errors ? result.errors[0].title : 'Ошибка регистрации. Попробуйте снова.';
        }
      } catch (error) {
        // Выводим сообщение об ошибке сети
        this.errorMessage = 'Ошибка сети. Проверьте подключение к интернету.';
        console.error('Ошибка регистрации:', error);
      }
    }
  }
};
</script>


<style scoped>

.form-wrapper {
  display: flex;
  background-color: #FAFDFC ;
  width: 100%;
  justify-content: center;
}

@media (width < 768px) {
  .form-wrapper {
    flex-direction: column;
  }
}

.form {
  background-color: #FAFDFC;
  padding: 0 20px;
}

@media (width >= 768px) {
  .form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (width >= 768px) and (width < 1280px) {
  .form {
    padding: 50px 98px;
    border-radius: 10px;
    top: 24%;
    position: absolute;
  }
}

.title {
  font-size: 22px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 19px;
  text-align: center;
}

@media (width >= 768px) {
  .title {
    font-size: 36px;
  }
}

.text {
  font-size: 14px;
  font-weight: 400;
  color: #686868;
  margin: 0;
}

@media (width >= 768px) {
  .text-password {
    font-size: 16px;
  }
}

.text-password {
  color: #A3A3A3;
  font-size: 12px;
  font-weight: 300;
  text-align: right;
  margin: 0;
}


@media (width >= 768px) {
  .text-password {
    font-size: 14px;
  }
}

/* Общие стили для полей формы */

.form-field-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 504px;
}

@media (width < 768px) {
  .form-field {
    width: 100%;
  }
}

.form-field input {
  padding: 18px 20px;
  border: 1px solid #D5D6DE;
  border-radius: 10px;
  background: #FFFFFF;
  font-size: 16px;
}

@media (width < 768px) {
  .form-field input {
    font-size: 12px;
  }
}

/* Кнопки */

.btn-wrapper {
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.btn {
  width: 100%;
  font-size: 16px;
  height: 52px;
}

@media (width >= 768px) {
  .logo {
    font-size: 20px;
    height: 56px;
  }
}

.input-error {
  border: 1px solid red;
}

.error-message {
  color: red;
  margin: 10px 0;
  font-size: 14px;
}
</style>