<template>
  <div class="logo">
    <router-link to="/">
      <img class="logo" src="@/assets/logo.svg" alt="ДомШеринг" />
    </router-link>
  </div>
  <div class="wrapper">
    <RegistrationList />
    <picture>
      <source media="(min-width: 1600px)" srcset="@/assets/registration.jpg" width="900"
        height="880">
      <source media="(min-width: 1280px)" srcset="@/assets/registration-1280.jpg" width="580"
        height="880">
      <img class="picture" src="@/assets/registration-768.jpg" height="900">
    </picture>
  </div>
</template>

<script>
import RegistrationList from './RegistrationList.vue';

export default {
name: 'RegistrationPage',
components: {
  RegistrationList
}
}
</script>

<style scoped>

.logo {
padding: 10px;
width: 80px;
}

@media (width >= 768px) {
.logo {
  width: 110px;
}
}

@media (width < 1280px) {
.logo {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}
}

@media (width >= 1280px) {
.logo {
  position: absolute;
}
}

.wrapper-logo {
padding: 20px;
}

.wrapper {
display: flex;
align-items: center;
}

@media (width < 768px) {
.wrapper {
  padding: 0 0 40px;
}
}

@media (width < 1280px) {
.wrapper {
  flex-direction: column;
}
}

@media (width < 768px) {
.picture {
  display: none;
}
}

@media (width < 1280px) {
.picture {
  width: 100%;
}
}

@media (width >= 1280px) and (width < 1600px){
.picture {
  width: 580px;
  height: 880px;
}
}

</style>