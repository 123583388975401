<template>
  <div class="view-card">
    <AppHeader />
    <!-- <SearchForm /> -->
    <!-- Проверяем, что объект существует, перед тем как его отображать -->
    <div v-if="object">
      <div class="card-wrapper">
        <button class="btn btn-light" @click="$router.go(-1)">
          <svg class="arrow-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <path fill="#199057" d="M9.57 5.18c.19 0 .38.07.53.22.29.29.29.77 0 1.06L4.56 12l5.54 5.54c.29.29.29.77 0 1.06-.29.29-.77.29-1.06 0l-6.07-6.07a.754.754 0 0 1 0-1.06L9.04 5.4c.15-.15.34-.22.53-.22Z"/>
            <path fill="#199057" d="M3.67 11.25H20.5c.41 0 .75.34.75.75s-.34.75-.75.75H3.67c-.41 0-.75-.34-.75-.75s.34-.75.75-.75Z"/>
          </svg>
          Назад
        </button>
        <h2>{{ object.nm }}</h2>
      </div>

      <PhotosBlock :cot-id="object.id" />

      <div class="specification">
        <div class="objects">
          <h3>Описание</h3>
          <p class="h3-description">{{ object.descr }}</p>
        </div>

        <div class="objects">
          <h3>Удобства</h3>
          <div class="comfort-list">
            <div class="comfort-item" v-for="facility in facilities" :key="facility.id">
              <!-- Используем require для динамической загрузки иконки -->
              <img :src="getIconPath(facility.icon)" :alt="facility.nm" />
              <p class="h3-description">{{ facility.nm }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="objects">
        <h3>Показать доступные варианты</h3>
        <Options :id="object.id" />
      </div>

      <div class="objects">
        <h3>Расположение</h3>
        <p>{{ object.object_location }}</p>
        <div id="map" class="map"></div> <!-- Карта будет здесь -->
      </div>
    </div>

    <!-- Сообщение, если объект не загружен или отсутствует -->
    <div v-else>
      <p>Загрузка...</p>
    </div>

    <AppFooter />
  </div>
</template>

<script>
import axios from 'axios';
import AppHeader from './AppHeader.vue';
import AppFooter from './AppFooter.vue';
//import SearchForm from './SearchForm.vue';
import PhotosBlock from './PhotosBlock.vue';
import Options from './Options.vue';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import {nextTick} from "vue"; // Подключаем Leaflet

export default {
  name: 'ViewCard',
  props: ['id'],
  components: {
    AppHeader,
    AppFooter,
    //SearchForm,
    PhotosBlock,
    Options
  },
  data() {
    return {
      object: null,     // Данные об объекте, полученные с API
      facilities: [],   // Удобства, полученные с API
      map: null, // Переменная для карты
    };
  },
  watch: {
    // Следим за изменениями в данных объекта и инициализируем карту, если координаты есть
    object(newVal) {
      if (newVal && newVal.latitude && newVal.longitude) {
        this.initMap(); // Инициализация карты
      }
    }
  },
  methods: {
    async fetchObjectData() {
      try {
        const response = await axios.get(`https://лк.домшеринг.рф/api/voa?sort=-id&filters[id-e]=${this.id}`);
        const objects = response.data.data;

        if (objects && objects.length > 0) {
          this.object = objects[0]; // Присваиваем первый объект из ответа
          console.log('Объект загружен:', this.object);
        } else {
          console.error('Объект не найден');
        }
      } catch (error) {
        console.error('Ошибка при загрузке данных объекта:', error);
      }
    },
    async fetchFacilitiesData() {
      try {
        const response = await axios.get(`https://лк.домшеринг.рф/api/vof?filters[cot_id-e]=${this.id}`);
        this.facilities = response.data.data;
      } catch (error) {
        console.error('Ошибка при загрузке удобств:', error);
      }
    },
    getIconPath(icon) {
      try {
        // Используем require для загрузки иконки
        return require(`@/assets/${icon}`);
      } catch (error) {
        console.error('Ошибка при загрузке иконки:', error);
        return '';  // Возвращаем пустую строку, если иконка не найдена
      }
    },
    // Инициализация карты с использованием Leaflet
    initMap() {
      nextTick(() => {
      if (!this.object || !this.object.latitude || !this.object.longitude) {
        console.error('Координаты объекта отсутствуют');
        return;
      }

      const latitude = parseFloat(this.object.latitude);
      const longitude = parseFloat(this.object.longitude);

      if (isNaN(latitude) || isNaN(longitude)) {
        console.error('Некорректные координаты:', latitude, longitude);
        return;
      }

      // Инициализация карты с центром на объекте
        this.map = L.map('map', {
          center: [latitude, longitude],  // Координаты Санкт-Петербурга
          zoom: 16,
          zoomAnimation: false,
          attributionControl: false  // Отключаем стандартную атрибуцию
        });

        // Создаем кастомную атрибуцию
        const myAttrControl = L.control.attribution().addTo(this.map);
        myAttrControl.setPrefix('<a href="https://leafletjs.com/">Leaflet</a>');  // Устанавливаем кастомный префикс атрибуции

        // Добавляем слой карт от OpenStreetMap
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 19,
          detectRetina: true,
          attribution: 'Data by &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, under <a href="https://opendatacommons.org/licenses/odbl/">ODbL.</a>'
        }).addTo(this.map);

        const customIcon = L.icon({
          iconUrl: require('@/assets/location_map.svg'),  // Путь к вашей иконке маркера
          iconSize: [25, 41], // Размер иконки
          iconAnchor: [12, 41], // Точка привязки иконки (середина внизу)
          popupAnchor: [1, -34], // Точка, откуда будет "выплывать" окно
          // shadowUrl: 'https://your-site.com/path/to/marker-shadow.png',  // Путь к тени маркера (если нужно)
          shadowSize: [41, 41], // Размер тени
        });
      // Добавление маркера на карте
      const marker = L.marker([latitude, longitude], { icon: customIcon }).addTo(this.map);

      // Всплывающее окно (popup) с информацией об объекте и его изображением
      const popupContent = `
        <div>
          <strong>${this.object.nm}</strong><br>
          ${this.object.object_location}<br>
          <img src="https://лк.домшеринг.рф/file/${this.object.file_id}/${this.object.file_name}" alt="${this.object.nm}" style="width: 100px; height: auto;">
        </div>
      `;

      marker.bindPopup(popupContent);

      // Обработка клика на маркер для перехода на страницу объекта
      marker.on('click', () => {
        this.$router.push({ name: 'ViewCard', params: { id: this.object.id } });
      });
      });
    }
  },
  mounted() {
    this.fetchObjectData(); // Загружаем объект при монтировании компонента
    this.fetchFacilitiesData(); // Загружаем удобства
    const startDate = this.$route.query.startDate;
    const endDate = this.$route.query.endDate;
    const guestCount = this.$route.query.guestCount;

    console.log('Даты:', startDate, endDate);
    console.log('Количество гостей:', guestCount);
  }
};
</script>

<style scoped>
.map {
  width: 100%;
  height: 300px;
  margin-top: 20px;
}

  .btn {
    font-size: 14px;
  }

  @media (width >= 768px) {
  .btn {
    font-size: 16px;
  }
}

  .btn-light {
    gap: 12px;
    width: 160px;
    height: 48px;
  }

  @media (width >= 768px) {
  .btn-light {
    height: 56px;
  }
}

  @media (width >= 768px) {
  .light-wrapper {
    margin: 62px 43px 64px;
  }
}

h2 {
  text-align: center;
  margin: 0;
}

.card-wrapper {
  display: flex;
  gap: 30px;
  flex-direction: column;
  padding: 30px 20px;
}

@media (width >= 768px) {
  .card-wrapper  {
    padding: 64px 80px;
  }
}


.specification {
  padding: 30px 0 0;
}

@media (width >= 768px) {
  .specification {
    padding: 60px 0 0;
  }
}

.cards {
  margin-top: 22px;
}

.comfort-list {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

@media (width > 460px) and (width < 910px) {
  .comfort-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    gap: 20px;
  }
}


@media (width >= 910px) and (width < 1140px){
  .comfort-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    gap: 20px;
  }
}

  @media (width >= 1140px) {
  .comfort-list {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.comfort-item {
  text-align: center;
  display: flex;
  align-items: center;
  gap: 12px;
}


</style>