<template>
  <div class="cards">
    <div class="cards-wrapper" v-for="card in cards" :key="card.id">
      <img :src="`https://лк.домшеринг.рф/file/${card.file_id}/${card.file_name}`"  alt="Card" />
      <div class="card">
        <h3>{{ card.nm }}</h3>
        <div class="wrapper">
          <!-- Количество гостей -->
          <div class="card-wrapper">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none">
              <path fill="#199057" fill-rule="evenodd" d="M9.566 2.853a3.235 3.235 0 1 0 0 6.47 3.235 3.235 0 0 0 0-6.47ZM3.978 6.088a5.588 5.588 0 1 1 11.176 0 5.588 5.588 0 0 1-11.176 0Z" clip-rule="evenodd"/>
              <path fill="#199057" fill-rule="evenodd" d="M3.68 13.946a9.986 9.986 0 0 1 15.395 5.018 1.177 1.177 0 0 1-1.12 1.536H1.176a1.176 1.176 0 0 1-1.12-1.536 9.986 9.986 0 0 1 3.624-5.018Zm5.886.434a7.633 7.633 0 0 0-6.582 3.767h13.163a7.633 7.633 0 0 0-6.581-3.767Z" clip-rule="evenodd"/>
            </svg>
            <p class="card-text">{{ card.max_guests }} чел.</p>
          </div>

          <!-- Площадь (если указана) -->
          <div v-if="card.square > 0" class="card-wrapper card-wrapper-gap-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" fill="none">
              <path stroke="#199057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.538 21.5h16.924c.85 0 1.538-.689 1.538-1.538V3.038c0-.85-.689-1.538-1.538-1.538H2.538C1.688 1.5 1 2.189 1 3.038v16.924c0 .85.689 1.538 1.538 1.538ZM1 10.73 9.462 1.5M21 10.73 12.54 1.5M1 12.269l8.462 9.23M21 12.269l-8.461 9.23"/>
            </svg>
            <p class="card-text">{{ card.square }} м²</p>
          </div>

          <!-- Завтрак -->
          <div class="card-wrapper card-wrapper-gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" fill="none">
              <path stroke="#199057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.308 6.885h12.308a.77.77 0 0 1 .769.769v6.923a3.077 3.077 0 0 1-3.077 3.077H5.616a3.077 3.077 0 0 1-3.077-3.077V7.654a.77.77 0 0 1 .769-.77v0ZM1 21.5h20M16.385 8.423h1.538a3.077 3.077 0 1 1 0 6.154h-1.538M4.846 1.5v1.538M12.539 1.5v1.538M8.692 1.5v1.538"/>
            </svg>
            <p class="card-text">{{ card.with_breakfast === '1' ? 'Завтрак включен' : 'Завтрак не включен' }}</p>
          </div>

          <!-- Можно с животными -->
          <div class="card-wrapper card-wrapper-gap-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="23" fill="none">
              <path stroke="#199057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 16.5c1.105 0 2-1.343 2-3s-.895-3-2-3-2 1.343-2 3 .895 3 2 3ZM9 7.5c1.105 0 2-1.343 2-3s-.895-3-2-3-2 1.343-2 3 .895 3 2 3ZM19 7.5c1.105 0 2-1.343 2-3s-.895-3-2-3-2 1.343-2 3 .895 3 2 3ZM25 16.5c1.105 0 2-1.343 2-3s-.895-3-2-3-2 1.343-2 3 .895 3 2 3ZM20 17.5c0 2.76-3.24 4-6 4s-6-1.24-6-4 2-7 6-7 6 4.24 6 7Z"/>
            </svg>
            <p class="card-text">{{ card.with_pets === '1' ? 'Можно с животными' : 'Нельзя с животными' }}</p>
          </div>

          <!-- Комнаты (если указаны) -->
          <div v-if="card.num_of_rooms > 0" class="card-wrapper card-wrapper-gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" fill="none">
              <path stroke="#199057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.23 10.731h-6.153a.77.77 0 0 0-.77.77v9.23c0 .425.345.77.77.77h6.154a.77.77 0 0 0 .769-.77v-9.23a.77.77 0 0 0-.77-.77ZM20.23 1.5h-6.153a.77.77 0 0 0-.77.77v3.092c0 .424.345.769.77.769h6.154A.77.77 0 0 0 21 5.36V2.27a.77.77 0 0 0-.77-.769ZM7.923 1.5H1.77A.77.77 0 0 0 1 2.27v9.23c0 .425.344.77.77.77h6.153a.77.77 0 0 0 .77-.77V2.27a.77.77 0 0 0-.77-.77ZM7.923 16.87H1.77a.77.77 0 0 0-.769.769v3.092c0 .425.344.77.77.77h6.153a.77.77 0 0 0 .77-.77V17.64a.77.77 0 0 0-.77-.77Z"/>
            </svg>
            <p class="card-text">{{ card.num_of_rooms }} комнаты</p>
          </div>
        </div>
        <!-- Цена и кнопка бронирования -->
        <div class="card-price">
          <p class="card-text-price">{{ card.price }} р/сутки</p>
        </div>
        <!-- <button v-if="this.$route.query.startDate && this.$route.query.endDate" class="btn" @click="goToBook(card.id)">
          Перейти к бронированию
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none">
            <path fill="#fff" d="M10.12 12.547a.494.494 0 0 1-.353-.147.503.503 0 0 1 0-.707L13.46 8 9.767 4.307a.503.503 0 0 1 0-.707.503.503 0 0 1 .706 0l4.047 4.047a.503.503 0 0 1 0 .706L10.473 12.4c-.1.1-.226.147-.353.147Z"/>
            <path fill="#fff" d="M14.053 8.5H2.833a.504.504 0 0 1-.5-.5c0-.273.227-.5.5-.5h11.22c.274 0 .5.227.5.5s-.226.5-.5.5Z"/>
          </svg>
        </button> -->
        <button class="btn" @click="goToBook(card.id)">
          Перейти к бронированию
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none">
            <path fill="#fff" d="M10.12 12.547a.494.494 0 0 1-.353-.147.503.503 0 0 1 0-.707L13.46 8 9.767 4.307a.503.503 0 0 1 0-.707.503.503 0 0 1 .706 0l4.047 4.047a.503.503 0 0 1 0 .706L10.473 12.4c-.1.1-.226.147-.353.147Z"/>
            <path fill="#fff" d="M14.053 8.5H2.833a.504.504 0 0 1-.5-.5c0-.273.227-.5.5-.5h11.22c.274 0 .5.227.5.5s-.226.5-.5.5Z"/>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'CardList',
  props: {
    id: {
      type: String,
      required: true, // ID объекта (cot_id)
    },
  },
  data() {
    return {
      cards: [], // Массив карточек
    };
  },
  mounted() {
    this.fetchCards();
  },
  methods: {

    async fetchCards() {
      try {
        // Получаем даты и количество гостей из props или query параметров
        //const startDate = this.$route.query.startDate ? new Date(this.$route.query.startDate) : null;
        //const endDate = this.$route.query.endDate ? new Date(this.$route.query.endDate) : null;
        const guestCount = this.$route.query.guestCount || 1;

        // Собираем параметры для запроса
        let query = `https://лк.домшеринг.рф/api/vor?filters[cot_id-e]=${this.id}&filters[max_guests-ge]=${guestCount}`;

        const response = await axios.get(query);

        // Преобразуем данные из API
        let fetchedCards = response.data.data;

        // Фильтруем карточки по датам
        /* if (startDate && endDate) {
          fetchedCards = fetchedCards.filter(card => {
            const cardStartDate = card.start_dt ? new Date(card.start_dt) : null;
            const cardEndDate = card.end_dt ? new Date(card.end_dt) : null;

            // Логика фильтрации: если хотя бы один период не пересекается, карта отображается
            if (cardStartDate && cardEndDate) {
              return endDate < cardStartDate || startDate > cardEndDate;
            }
            return true; // Если у карточки нет дат, считаем её доступной
          });
        } */

        this.cards = fetchedCards; // Обновляем карточки, только с отфильтрованными данными
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
      }
    },
    goToBook(id) {
      // Проверяем, авторизован ли пользователь через Vuex store
      if (!this.$store.getters.isLoggedIn) {
        // Если пользователь не авторизован, сохраняем текущий маршрут и параметры
        const startDateRaw = this.$route.query.startDate;
        const endDateRaw = this.$route.query.endDate;
        const guestCount = this.$route.query.guestCount || 1;

        // Формируем маршрут с параметрами для возврата
        const targetRoute = {
          name: 'ReservationsCard',
          params: { id },
          query: {
            startDate: startDateRaw && startDateRaw.trim() !== '' ? startDateRaw : '',
            endDate: endDateRaw && endDateRaw.trim() !== '' ? endDateRaw : '',
            guestCount: guestCount || 1,
          }
        };

        // Сохраняем маршрут в localStorage
        localStorage.setItem('targetRoute', JSON.stringify(targetRoute));

        // Перенаправляем пользователя на страницу NotAuthorized или LoginPage
        this.$router.push({ name: 'NotAuthorized' });
      } else {
        // Если пользователь авторизован, просто переходим на страницу бронирования
        const startDateRaw = this.$route.query.startDate;
        const endDateRaw = this.$route.query.endDate;
        const guestCount = this.$route.query.guestCount || 1;

        // Если даты переданы и не пустые строки, оставляем их как есть, иначе передаем пустую строку
        const startDate = startDateRaw && startDateRaw.trim() !== '' ? startDateRaw : '';
        const endDate = endDateRaw && endDateRaw.trim() !== '' ? endDateRaw : '';

        // Формируем query-параметры
        const queryParams = {
          startDate,
          endDate,
          guestCount: guestCount || 1,
        };

        // Переход на страницу бронирования с передачей параметров
        this.$router.push({
          name: 'ReservationsCard',
          params: { id },
          query: queryParams
        });
      }
    }
  }
}
</script>

<style scoped>

.cards {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

@media (width >= 768px) {
  .cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }
}

@media (width >= 1236px) {
  .cards {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (width >= 1600px) {
  .cards {
    grid-template-columns: repeat(4, 1fr);
  }
}

.cards-wrapper {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 280px;
}

@media (width >= 870px) {
  .cards-wrapper {
    width: 346px;
  }
}

.cards-wrapper img {
  width: 280px;
  height: 202px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

@media (width >= 870px) {
  .cards-wrapper img {
    width: 346px;
    height: 250px;
  }
}

.card {
  padding: 20px;
  width: 240px;
}

@media (width >= 870px) {
  .card {
    width: 306px;
  }
}

.cards img {
  width: 280px;
  height: 202px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

@media (width >= 870px) {
  .cards img {
    width: 346px;
    height: 250px;
  }
}

h3 {
  text-align: left;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 16px;
}

.card-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.card-text {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  font-family: inherit;
  text-align: left;
}

@media (width >= 768px) {
  .card-text {
    font-size: 20px;
  }
}

@media (width >= 1440px) {
  .card-text {
    font-size: 22px;
  }
}

.card-wrapper-gap-1 {
  gap: 15px
}

.card-wrapper-gap-2 {
  gap: 17px
}

.card-wrapper-gap-3 {
  gap: 12px
}

.card-text-price {
  font-size: 18px;
  font-weight: 700;
  color: #199057;
}

@media (width >= 768px) {
  .card-text-price {
    font-size: 22px;
  }
}

@media (width >= 1440px) {
  .card-text-price {
    font-size: 24px;
  }
}

.card-price {
  display: flex;
  justify-content: flex-end;
}
.btn {
  padding: 16px 30px;
  width: 100%;
  height: 56px;
  font-size: 16px;
  line-height: 20px;
}

@media (width >= 768px) {
  .btn{
    font-size: 18px;
    height: 64px;
  }
}

@media (width >= 1440px) {
  .btn{
    font-size: 20px;
  }
}

</style>