import { createStore } from 'vuex';

export default createStore({
  state: {
    token: localStorage.getItem('token') || null,
    userId: localStorage.getItem('userId') || null, // Сохраняем только userId
  },
  mutations: {
    SET_TOKEN(state, { token, exp_dt, user }){
      state.token = token;
      state.userId = user.id; // Сохраняем только id пользователя

      localStorage.setItem('token', token);
      localStorage.setItem('tokenExpiration', exp_dt);
      localStorage.setItem('userId', user.id); // Сохраняем только id пользователя
    },
    LOGOUT(state) {
      state.token = null;
      state.userId = null;
      localStorage.removeItem('token');
      localStorage.removeItem('tokenExpiration');
      localStorage.removeItem('userId');
    }
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const response = await fetch('https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(credentials)
        });
        const data = await response.json();
        if (response.ok) {
          const { token, exp_dt, user } = data.data;
          commit('SET_TOKEN', { token, exp_dt, user });
          return true;
        }
        return false;
      } catch (error) {
        console.error('Ошибка при авторизации:', error);
        return false;
      }
    },
    logout({ commit }) {
      commit('LOGOUT');
    },
    async checkToken({ commit, state }) {
      const tokenExpiration = localStorage.getItem('tokenExpiration');
      const currentTime = new Date().toISOString();

      if (tokenExpiration && currentTime > tokenExpiration) {
        commit('LOGOUT');
        return false;  // Токен истек
      }

      return state.token;
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    userId: state => state.userId, // Возвращаем только userId
    token: state => state.token
  }
});