<template>
  <div class="shadow">
    <div id="app">

      <!-- Здесь будет отображаться текущая страница -->
      <router-view />
    </div>
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {

  },
  created () {
    document.title = "ДомШеринг";
  }
}
</script>

<style>
body {
  margin: 0 auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  background-color: #ffffff;
  font-weight: 300;
}

@media (width >= 768px) {
  body {
    font-size: 20px;
    line-height: 24px;
    } 
  }

@media (width >= 1280px) {
  body {
    font-size: 25px;
    line-height: 30px;
    }
  }

.shadow {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 20px rgba(7, 65, 115, 0.15);
}

h1 {
  font-size: 25px;
  margin: 0;
  line-height: 30px;
}

@media (width >= 768px) {
  h1 {
    font-size: 40px;
    line-height: 50px;
  } 
}

@media (width >= 1440px) {
  h1 {
    font-size: 50px;
    line-height: 60px;
  }
}

h2 {
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 30px;
  text-align: center;
}

@media (width >= 768px) {
  h2 {
    font-size: 30px;
    line-height: 36px;
    margin: 0 0 40px;
    text-align: left;
  } 
}

@media (width >= 1440px) {
  h2 {
    font-size: 36px;
    line-height: 44px;
    margin: 0 0 50px;
  } 
}

h3 {
  font-size: 16px;
  margin: 0;
  margin-bottom: 14px;
}

@media (width >= 768px) {
  h3 {
    font-size: 24px;
    margin-bottom: 22px;
  } 
}

@media (width >= 1440px) {
  h3 {
    font-size: 26px;
  }
}

.h3-description {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
}

@media (width >= 768px) {
  .h3-description {
    font-size: 20px;
    line-height: 28px;
    }
  }

@media (width >= 1440px) {
  .h3-description {
    font-size: 22px;
    line-height: 30px;
    }
  }

.description {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin-top: 8px;
}

@media (width >= 768px) {
  .description {
    font-size: 28px;
    line-height: 36px;
    margin-top: 16px;
    } 
  }

@media (width >= 1440px) {
  .description {
    font-size: 36px;
    line-height: 44px;
    }
  }

.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0;
  flex-direction: column;
  /* gap: 16px; */
}

@media (width >= 768px) {
  .hero {
    gap: 30px;
    flex-direction: row;
  } 
}

@media (width < 768px) {
  .hero-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

li {
  list-style: none;
}

#app {
  font-family: Hind Madurai, Cabin, sans-serif;
  /* text-align: center; */
  background: #FDFEFA;
}

.btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 147px;
  height: 56px;
  padding: 16px 32px;
  background-color: #199057;
  color: white;
  border: none;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
  gap: 8px;
  border-radius: 10px;
  opacity: 1;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #157a45;
}

.btn-light {
    background-color: white;
    color: #199057;
    border: 2px solid #199057;
  }

.light-wrapper {
  background: #CFE89F;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin: -20px 0px 50px;
}

@media (width >= 768px) {
  .light-wrapper {
    margin: -20px 43px 64px;
  } 
}

.dark-wrapper {
  background: #2D2D2D;
  width: 100%;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin: 50px;
}

.objects {
  background: #FFFFFF;
  box-shadow: 4px 4px 20px 0px #0000000D;
  border-radius: 10px;
  padding: 30px 20px;
  margin-top: 20px;
}

@media (width >= 768px) {
  .objects {
    margin: 0 43px 64px;
    padding: 40px 37px;
  } 
}

</style>