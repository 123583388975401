<template>
  <div>
    <div class="objects">
      <h3>Удобства</h3>
      <div class="comfort-list">
        <div class="comfort-item"  v-for="key in keys" :key="key.id">
          <img src="@/assets/shower.svg" alt="" />
          <p class="h3-description">{{ key.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ComfortList',
  data() {
    return {
      keys: [
        {
          id: 1,
          image: "@/assets/parking.svg",
          description: 'Бесплатная парковка',
        },
        {
          id: 2,
          image: "@/assets/shower.svg",
          description: 'Личная душевая',
        },
        {
          id: 3,
          image: "@/assets/toilet.svg",
          description: 'Туалет внутри',
        },
        {
          id: 4,
          image: "@/assets/wifi.svg",
          description: 'Wi-Fi',
        },
        {
          id: 5,
          image: "@/assets/smoke.svg",
          description: 'Можно курить',
        },
        {
          id: 6,
          image: "@/assets/wind.svg",
          description: 'Кондиционер',
        },
        {
          id: 7,
          image: "@/assets/tv.svg",
          description: 'Телевизор',
        },
        {
          id: 8,
          image: "@/assets/snow.svg",
          description: 'Холодильник',
        },
        {
          id: 9,
          image: "@/assets/hairdryer.svg",
          description: 'Фен',
        },
        {
          id: 10,
          image: "@/assets/iron.svg",
          description: 'Утюг',
        }
      ]
    }
  }
};
</script>

<style scoped>
.comfort-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  gap: 20px;
}

@media (width < 435px) {
  .comfort-list {
    display: flex;
    flex-direction: column;
  } 
}

@media (width >= 648px) and (width < 768px){
  .comfort-list {
    grid-template-columns: 1fr 1fr 1fr;
  } 
}

@media (width >= 768px) {
  .comfort-list {
    grid-template-columns: 1fr 1fr;
  } 
}

  @media (width >= 1140px) {
  .comfort-list {
    display: flex;
    justify-content: space-between;
  } 
}

.comfort-item {
  text-align: center;
  display: flex;
  align-items: center;
  gap: 12px;
}

</style>