<template>
  <div>
    <AppHeader />
    <div class="objects">
      <h1>Личный профиль</h1>
      <div class="form">
        <div class="form-field-wrapper">
          <div class="form-field">
            <p class="text">Имя</p>
            <input type="text" v-model="userName" placeholder="Введите ваше имя" />
          </div>
          <div class="form-field">
            <p class="text">Телефон</p>
            <input type="text" v-model="userPhone" placeholder="Введите ваш телефон" />
          </div>
          <div class="form-field">
            <p class="text">Email</p>
            <input type="email" v-model="userEmail" placeholder="Введите ваш email" />
          </div>
          <div class="form-field">
            <p class="text">Населенный пункт</p>
            <input type="text" v-model="userLocation" placeholder="Введите населенный пункт" @input="getDadataSuggestions" />
            <!-- Подсказки Dadata -->
            <ul v-if="suggestions.length" class="suggestions-list">
              <li v-for="(suggestion, index) in suggestions" :key="index" @click="selectSuggestion(suggestion.value)">
                {{ suggestion.value }}
              </li>
            </ul>
          </div>
          <!-- Ошибки валидации -->
          <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
          <button class="btn" :disabled="!isFormValid" @click="saveChanges">Сохранить изменения</button>
        </div>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './AppHeader.vue';
import AppFooter from './AppFooter.vue';

export default {
  name: 'ProfilePage',
  components: {
    AppHeader,
    AppFooter
  },
  data() {
    return {
      userId: null,
      userName: '',
      userPhone: '',
      userEmail: '',
      userLocation: '',
      originalUserData: {},
      suggestions: [],  // Подсказки Dadata
      errorMessage: '',
      dadataToken: '2467755542e320d99d52db07195776888a7e0366' // Ваш API ключ от Dadata
    };
  },
  computed: {
    isFormValid() {
      return this.isDataChanged && this.validateFields();
    },
    isDataChanged() {
      return (
          this.userName !== this.originalUserData.nm ||
          this.userPhone !== this.originalUserData.phone ||
          this.userEmail !== this.originalUserData.mail ||
          this.userLocation !== this.originalUserData.remark_tx
      );
    }
  },
  methods: {
    async fetchUserProfile() {
      try {
        const myHeaders = new Headers();
        myHeaders.append('token', this.$store.getters.token);

        const requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        const response = await fetch('https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/usr', requestOptions);
        const result = await response.json();

        if (response.ok) {
          const userData = result.data[0];
          // Обновляем значения после загрузки
          this.userId = userData.id;
          this.userName = userData.nm || '';
          this.userPhone = userData.phone || '';
          this.userEmail = userData.mail || '';
          this.userLocation = userData.remark_tx || '';

          this.originalUserData = { ...userData };
          // Валидируем поля после загрузки данных
          this.validateFields();
        } else {
          console.error('Ошибка при загрузке данных профиля:', result.errors);
        }
      } catch (error) {
        console.error('Ошибка запроса:', error);
      }
    },
    validateFields() {
      this.errorMessage = '';

      if (!this.userName.trim() || !this.userEmail.trim() || !this.userPhone.trim() || !this.userLocation.trim()) {
        this.errorMessage = 'Все поля должны быть заполнены.';
        return false;
      }

      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(this.userEmail)) {
        this.errorMessage = 'Неверный формат email.';
        return false;
      }

      const phonePattern = /^\+7\d{10}$/;
      if (!phonePattern.test(this.userPhone)) {
        this.errorMessage = 'Номер телефона должен начинаться с +7 и содержать 10 цифр.';
        return false;
      }

      return true;
    },
    async getDadataSuggestions() {
      if (!this.userLocation) {
        this.suggestions = [];
        return;
      }

      const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address';
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + this.dadataToken
        },
        body: JSON.stringify({ query: this.userLocation })
      };

      try {
        const response = await fetch(url, options);
        const result = await response.json();

        if (response.ok) {
          this.suggestions = result.suggestions;
        } else {
          this.suggestions = [];
        }
      } catch (error) {
        console.error('Ошибка получения подсказок:', error);
        this.suggestions = [];
      }
    },
    selectSuggestion(value) {
      this.userLocation = value;
      this.suggestions = [];
    },
    async saveChanges() {
      if (!this.isFormValid) return;

      try {
        const myHeaders = new Headers();
        myHeaders.append('token', this.$store.getters.token);
        myHeaders.append('Content-Type', 'application/json');

        const raw = JSON.stringify({
          nm: this.userName,
          mail: this.userEmail,
          phone: this.userPhone,
          remark_tx: this.userLocation
        });

        const requestOptions = {
          method: 'PATCH',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        const response = await fetch(`https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/usr/${this.userId}`, requestOptions);
        const result = await response.json();

        if (response.ok) {
          alert('Изменения успешно сохранены');
          this.fetchUserProfile(); // Обновляем данные профиля
        } else {
          console.error('Ошибка при сохранении данных:', result.errors);
        }
      } catch (error) {
        console.error('Ошибка запроса:', error);
      }
    }
  },
  mounted() {
    this.fetchUserProfile();
  }
};
</script>

<style scoped>


h1 {
  margin-bottom: 40px;
}
.btn {
  width: 100%;
}

.text {
  font-size: 16px;
  font-weight: 400;
  color: #686868;
  margin: 0;
}

/* Общие стили для полей формы */
.form-field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.form-field-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 34px;
  align-items: end;
}

@media (width >= 768px) and (width < 1280px) {
  .form-field-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  } 
}


@media (width >= 1280px) {
  .form-field-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  } 
}

.form-field label {
  color: #fff;
  margin-bottom: 8px;
}

.form-field input,
.form-field select {
  padding: 18px 20px;
  border: 1px solid #D5D6DE;
  border-radius: 10px;
  background: #FFFFFF;
  font-size: 16px;
}

.form {
  align-items: center;
  justify-content: center;
}

.objects {
  margin-top: 64px;
}

.btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.suggestions-list {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ddd;
  max-height: 150px;
  overflow-y: auto;
  font-size: 14px;
}

.suggestions-list li {
  padding: 10px;
  cursor: pointer;
}

.suggestions-list li:hover {
  background-color: #f0f0f0;
}

.error-message {
  color: red;
  margin: 10px 0;
  font-size: 14px;
}

</style>